/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// function scrollto1(){
//     zenscroll.toY(0);
// }
// function scrollto2(){
//     zenscroll.toY(1640);
// }

// var EPPZScrollTo =
// {
//     /**
//      * Helpers.
//      */
//     documentVerticalScrollPosition: function()
//     {
//         if (self.pageYOffset) return self.pageYOffset; // Firefox, Chrome, Opera, Safari.
//         if (document.documentElement && document.documentElement.scrollTop) return document.documentElement.scrollTop; // Internet Explorer 6 (standards mode).
//         if (document.body.scrollTop) return document.body.scrollTop; // Internet Explorer 6, 7 and 8.
//         return 0; // None of the above.
//     },

//     viewportHeight: function()
//     { return (document.compatMode === "CSS1Compat") ? document.documentElement.clientHeight : document.body.clientHeight; },

//     documentHeight: function()
//     { return (document.height !== undefined) ? document.height : document.body.offsetHeight; },

//     documentMaximumScrollPosition: function()
//     { return this.documentHeight() - this.viewportHeight(); },

//     elementVerticalClientPositionById: function(id)
//     {
//         var element = document.getElementById(id);
//         var rectangle = element.getBoundingClientRect();
//         return rectangle.top;
//     },

//     /**
//      * Animation tick.
//      */
//     scrollVerticalTickToPosition: function(currentPosition, targetPosition)
//     {
//         var filter = 0.07;
//         var fps = 60;
//         var difference = parseFloat(targetPosition) - parseFloat(currentPosition);

//         // Snap, then stop if arrived.
//         var arrived = (Math.abs(difference) <= 0.5);
//         if (arrived)
//         {
//             // Apply target.
//             scrollTo(0.0, targetPosition);
//             return;
//         }

//         // Filtered position.
//         currentPosition = (parseFloat(currentPosition) * (1.0 - filter)) + (parseFloat(targetPosition) * filter);

//         // Apply target.
//         scrollTo(0.0, Math.round(currentPosition));

//         // Schedule next tick.
//         setTimeout("EPPZScrollTo.scrollVerticalTickToPosition("+currentPosition+", "+targetPosition+")", (1000 / fps));
//     },

//     /**
//      * For public use.
//      *
//      * @param id The id of the element to scroll to.
//      * @param padding Top padding to apply above element.
//      */
//     scrollVerticalToElementById: function(id, padding)
//     {
//         var element = document.getElementById(id);
//         if (element == null)
//         {
//             console.warn('Cannot find element with id \''+id+'\'.');
//             return;
//         }

//         var targetPosition = this.documentVerticalScrollPosition() + this.elementVerticalClientPositionById(id) - padding;
//         var currentPosition = this.documentVerticalScrollPosition();

//         // Clamp.
//         var maximumScrollPosition = this.documentMaximumScrollPosition();
//         if (targetPosition > maximumScrollPosition) targetPosition = maximumScrollPosition;

//         // Start animation.
//         this.scrollVerticalTickToPosition(currentPosition, targetPosition);
//     }


// };




(function($) {

  $('.js-scroll-link').click(function(event) {

    event.preventDefault();
    var target = $(this).attr('href');

    var header = $('.js-header')

    if (header.css('z-index') === '1') {
      var headerOffset = $('.js-header').height() + 20;
    } else {
      var headerOffset = 20;
    }
    
    $('html, body').animate({
      scrollTop: ($(target).offset().top - headerOffset) + 'px'
    }, 'slow');

  })

})(jQuery);